export const classTypes = {
  0: "Titan",
  1: "Hunter",
  2: "Warlock",
  3: "Unknown"
}

export const genderTypes = {
  0: "Male",
  1: "Female",
  2: "Unknown"
}

export const raceTypes = {
  0: "Human",
  1: "Awoken",
  2: "Exo",
  3: "Unknown"
}
